import Vue    from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "home" */ '../views/Login.vue'),
      meta: {  guest: true }
    },{
      path: '/index.html',
      name: 'Index',
      component: () => import(/* webpackChunkName: "home" */ '../views/Login.vue'),
      meta: {  guest: true }
    },{
      path: '/engineer',
      name: 'Engineer',
      component: () => import( '../views/Engineer.vue'),
      meta: {  guest: true }
    },{
      path: '/forgotPassword',
      name: 'forgotPassword',
      component: () => import('../views/ForgotPassword.vue'),
      meta: {  guest: true }
    },{
      path: '/enhancedTest',
      name: 'EnhancedTest',
      component: () => import('../views/forms/EnhancedTest.vue'),
      meta: {  guest: true }
    },{
      path: '/testCertificate',
      name: 'TestCertificate',
      component: () => import('../views/forms/TestCertificate.vue'),
      meta: {  guest: true }
    },{
      path: '/selfOwnedCommercial',
      name: 'selfOwnedCommercial',
      component: () => import('../views/forms/SelfOwnedCommercial.vue'),
      meta: {  guest: true }
    }, {
      path: '/annualInspection',
      name: 'AnnualInspection',
      component: () => import('../views/forms/AnnualInspection.vue'),
      meta: {  guest: true }
    },{
      path: '/reBrand',
      name: 'Rebrand',
      component: () => import('../views/forms/Rebrand.vue'),
      meta: {  guest: true }
    },{
      path: '/meterRead',
      name: 'meterRead',
      component: () => import('../views/forms/meterRead.vue'),
      meta: {  guest: true }
    },{
      path: '/jobSheet',
      name: 'jobSheet',
      component: () => import('../views/forms/JobSheet.vue'),
      meta: {  guest: true }
    },{
      path: '/signup',
      name: 'Signup',
      component: () => import('../views/Signup.vue'),
      meta: {  guest: true }
    },{
      path: '/formReview',
      name: 'FormReview',
      component: () => import('../views/forms/FormReview.vue'),
      meta: {  guest: true }
    },{
      path: '/administratorView',
      name: 'AdministrataorView',
      component: () => import('../views/AdministratorView.vue'),
      meta: {  guest: true }
    },{
      path: '/userManagement',
      name: 'UserManagement',
      component: () => import('../views/UserManagement.vue'),
      meta: {  guest: true }
    }

  ]
})

router.beforeEach((to, from, next) => {

  if(to.matched.some(record => record.meta.requiresAuth)) {
      next();
 //     if (localStorage.getItem('jwt') == null) {
 //         next();
//          console.log("not signed in, goto /");
//          next({
 //             path: '/',
  //            params: { nextUrl: to.fullPath }
  //        })
  //    }
  } else if(to.matched.some(record => record.meta.guest)) {
   
          next()
  }else {
      next()
  }

})

export default router