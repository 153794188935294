import Vue     from  'vue'
import App     from  './App.vue'
import './registerServiceWorker'
import router  from  './router'
import vuetify from  './plugins/vuetify'
import Axios   from  'axios'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'

import Localbase from 'localbase'

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

import VueEasyLightbox from 'vue-easy-lightbox'
Vue.use(VueEasyLightbox)

import VueSignature from 'vue-signature-pad'
Vue.use(VueSignature);

import Customer from './components/Customer'
Vue.component('customer', Customer);

import DateSelector from './components/DateSelector'
Vue.component('DateSelector', DateSelector);

import SignaturePad from './components/SignaturePad'
Vue.component('SignaturePad', SignaturePad);

import TimeSelector from './components/TimeSelector'
Vue.component('TimeSelector', TimeSelector);

import EngineerUpdate from './components/EngineerUpdate'
Vue.component('EngineerUpdate', EngineerUpdate);

import mySnackBar  from './components/mySnackBar.vue'
Vue.component('mySnackBar', mySnackBar);

import uploadingDialog  from './components/uploadingDialog.vue'
Vue.component('uploadingDialog', uploadingDialog);


/////////////////////////////////////////////////////////////
//4-17-24 use uploadingRebrandDialog                      ///
//when uploading the reBrand form, as it also uploads photos/
/////////////////////////////////////////////////////////////
import uploadingRebrandDialog  from './components/uploadingRebrandDialog.vue'
Vue.component('uploadingRebrandDialog', uploadingRebrandDialog);
////////////////////////////////////////////////////////////



/////////////////////////////////////////////////////
//////////          some global refs ////////////////
/////////////////////////////////////////////////////

   Vue.config.productionTip  = false;

   const localURLs = { 
      "serverURL"                :  "http://localhost/server/public/",
      "loginURL"                 :  "http://localhost/server/public/login",
      "updateURL"                :  "http://localhost/server/public/update",
      "searchURL"                :  "http://localhost/server/public/search",
      "baseController"           :  "http://localhost/server/public/pdf/enhancedTest",
      "enhancedTestURL"          :  "http://localhost/server/public/pdf/enhancedTest", 
      "annualInspectionURL"      :  "http://localhost/server/public/pdf/annualInspection", 
      "reBrandURL"               :  "http://localhost/server/public/pdf/reBrand", 
      "jobSheetURL"              :  "http://localhost/server/public/pdf/jobSheet", 
      "testCertificateURL"       :  "http://localhost/server/public/pdf/testCertificate",
      "selfOwnedCommercialURL"   :  "http://localhost/server/public/pdf/selfOwnedCommercial",
      "uploadPDFToDropBoxURL"    :  "http://localhost/server/public/dropbox/uploadPDFToDropBox",
      "formsUploadStatusURL"     :  "http://localhost/server/public/formUpload/formsUploadStatus",
      "formUploadCompletedURL"   :  "http://localhost/server/public/formUpload/formUploadCompleted",
      "createUserURL"            :  "http://localhost/server/public/user/createUser",
      "readUserURL"              :  "http://localhost/server/public/user/readUser",
      "updateUserURL"            :  "http://localhost/server/public/user/updateUser",
      "deleteUserURL"            :  "http://localhost/server/public/user/deleteUser",
      "updatePasswordURL"        :  "http://localhost/server/public/user/updatePassword",
      "blankPhoto"               :  "http://localhost/server/public/photo/blankphoto",
      "photoUpload"              :  "http://localhost/server/public/photo/upload",

      "engineerMeter"             :  "http://localhost/server/public/engineerMeter",
      "engineerMeterImage"        :  "http://localhost/server/public/engineerMeterImage",
      "engineerMeterImageLink"    :  "http://localhost/server/public/engineerMeterImageLink",
      "engineerMeterImageEmpty"   :  "http://localhost/server/public/engineerMeterImageEmpty"
   }

   const remoteURLs = { 
      "serverURL"                :  "./server/public/",
      "loginURL"                 :  "./server/public/login",
      "updateURL"                :  "./server/public/update",
      "searchURL"                :  "./server/public/search",
      "baseController"           :  "./server/public/pdf/enhancedTest",
      "enhancedTestURL"          :  "./server/public/pdf/enhancedTest", 
      "annualInspectionURL"      :  "./server/public/pdf/annualInspection", 
      "reBrandURL"               :  "./server/public/pdf/reBrand", 
      "jobSheetURL"              :  "./server/public/pdf/jobSheet", 
      "testCertificateURL"       :  "./server/public/pdf/testCertificate",
      "selfOwnedCommercialURL"   :  "./server/public/pdf/selfOwnedCommercial",
      "uploadPDFToDropBoxURL"    :  "./server/public/dropbox/uploadPDFToDropBox",
      "formsUploadStatusURL"     :  "./server/public/formUpload/formsUploadStatus",
      "formUploadCompletedURL"   :  "./server/public/formUpload/formUploadCompleted",
      "createUserURL"            :  "./server/public/user/createUser",
      "readUserURL"              :  "./server/public/user/readUser",
      "updateUserURL"            :  "./server/public/user/updateUser",
      "deleteUserURL"            :  "./server/public/user/deleteUser",
      "updatePasswordURL"        :  "./server/public/user/updatePassword",
      "blankPhoto"               :  "./server/public/photo/blankphoto",
      "photoUpload"              :  "./server/public/photo/upload",

      "engineerMeter"             :  "./server/public/engineerMeter",
      "engineerMeterImage"        :  "./server/public/engineerMeterImage",
      "engineerMeterImageLink"    :  "./server/public/engineerMeterImageLink",
      "engineerMeterImageEmpty"   :  "./server/public/engineerMeterImageEmpty"

  
   }

   
   const fixedURLs = { 
      "serverURL"                :  "https://engineer.equalresponse.co.uk/server/public/",
      "loginURL"                 :  "https://engineer.equalresponse.co.uk/server/public/login",
      "updateURL"                :  "https://engineer.equalresponse.co.uk/server/public/update",
      "searchURL"                :  "https://engineer.equalresponse.co.uk/server/public/search",
      "baseController"           :  "https://engineer.equalresponse.co.uk/server/public/pdf/enhancedTest",
      "enhancedTestURL"          :  "https://engineer.equalresponse.co.uk/server/public/pdf/enhancedTest", 
      "annualInspectionURL"      :  "https://engineer.equalresponse.co.uk/server/public/pdf/annualInspection", 
      "reBrandURL"               :  "https://engineer.equalresponse.co.uk/server/public/pdf/reBrand", 
      "jobSheetURL"              :  "https://engineer.equalresponse.co.uk/server/public/pdf/jobSheet", 
      "testCertificateURL"       :  "https://engineer.equalresponse.co.uk/server/public/pdf/testCertificate",
      "selfOwnedCommercialURL"   :  "https://engineer.equalresponse.co.uk/server/public/pdf/selfOwnedCommercial",
      "uploadPDFToDropBoxURL"    :  "https://engineer.equalresponse.co.uk/server/public/dropbox/uploadPDFToDropBox",
      "formsUploadStatusURL"     :  "https://engineer.equalresponse.co.uk/server/public/formUpload/formsUploadStatus",
      "formUploadCompletedURL"   :  "https://engineer.equalresponse.co.uk/server/public/formUpload/formUploadCompleted",
      "createUserURL"            :  "https://engineer.equalresponse.co.uk/server/public/user/createUser",
      "readUserURL"              :  "https://engineer.equalresponse.co.uk/server/public/user/readUser",
      "updateUserURL"            :  "https://engineer.equalresponse.co.uk/server/public/user/updateUser",
      "deleteUserURL"            :  "https://engineer.equalresponse.co.uk/server/public/user/deleteUser",
      "updatePasswordURL"        :  "https://engineer.equalresponse.co.uk/server/public/user/updatePassword",
      "blankPhoto"               :  "https://engineer.equalresponse.co.uk/server/public/photo/blankphoto",
      "photoUpload"              :  "https://engineer.equalresponse.co.uk/server/public/photo/upload",

      
      "engineerMeter"             :  "https://engineer.equalresponse.co.uk/server/public/engineerMeter",
      "engineerMeterImage"        :  "https://engineer.equalresponse.co.uk/server/public/engineerMeterImage",
      "engineerMeterImageLink"    :  "https://engineer.equalresponse.co.uk/server/public/engineerMeterImageLink",
      "engineerMeterImageEmpty"   :  "https://engineer.equalresponse.co.uk/server/public/engineerMeterImageEmpty"
      
   }

   var localDevelopment           =  false;
   Vue.prototype.$restServer      = (localDevelopment) ? localURLs : fixedURLs;

   // make life easy and use the server for dev on the PWA only//
   /////////////////////////////////////////////////////////////
  // Vue.prototype.$restServer      = fixedURLs;
  
   Vue.prototype.$http            =  Axios;
   Vue.prototype.$dbName          =  'EqualResponse';
   
   
   Vue.mixin({
   
      methods: {
   
         storePdfReviewObject(pdfReviewObject) {
            localStorage.setItem("equalResponsePdfReviewObject", JSON.stringify( pdfReviewObject) );
         },
         resetPdfReviewObject() {
            localStorage.removeItem("equalResponsePdfReviewObject");
         },
         pdfReviewUpload() {
   
            const body    =  localStorage.getItem("equalResponsePdfReviewObject");
            const url     =  this.$restServer.uploadPDFToDropBoxURL;
            this.$http.post( url, body ) 
            .then(  (response) => {
                     console.log(response.data);
            })
            .catch( (e) => { 
               console.log(e);
            });
         },
         resetLocalStorage() {

            localStorage.removeItem("equalResponsePdfReviewObject");
            localStorage.removeItem("equalResponseFormReview" );
            
            localStorage.removeItem("annualInspectionFormReview" );
            localStorage.removeItem("enhancedTestFormReview");
            localStorage.removeItem("rebrandFormReview");
            localStorage.removeItem("jobSheetFormReview");
            localStorage.removeItem("testCertificateFormReview");
            localStorage.removeItem("selfOwnedCommercialFormReview");
       
            localStorage.removeItem("equalResponseEngineer");
            localStorage.removeItem("equalResponseCustomer");
            localStorage.removeItem("equalResponseSaleman");

         },
         
      },

   });

   export const store = new Vue({ 
                                 data:    {
                                    debugOn                         :  false,     // used to consoleLog
                                    formsUploadStatus               :  null,
                                    showByPassButton                :  false,      // false, show bypass button
                                    showManageUsersButton           :  false,
                                    formsAllowed                    :  [],
                                    version                         : "1.0.35",  
                                    signature                       :  null,
                                    photos                          :  [],
                                    formReviewResponse              :  null,
                                    formReviewObjects               :  {
                                        "jobSheet"             :  null,
                                        "reBrand"              :  null,
                                        "testCertificate"      :  null,
                                        "enhancedTest"         :  null,
                                        "annualInspection"     :  null,
                                        "selfOwnedCommercial"  :  null,
                                    }

                                 },

                                 methods: {
                                       removeDatabase( ){
                                          let db = new Localbase(  this.$dbName );
                                          if (db)
                                              db.delete();
                                       },
                                       removeDocument( customer , formName) {
                                          let db = new Localbase(  this.$dbName );
                                          if (db) {
                                              db.collection(customer).doc( formName).delete();
                                          }
                                       },
                                       consoleLog ( data ) {
                                          if (this.debugOn ) {
                                             console.log( data)
                                          }
                                       },  
                                       setFormsAllowed( formsArray )  { 

                                          try {
                                                const _formsAllowed  =  formsArray.map( Item => {
                                                     return Item.value.toLowerCase();
                                                });
                                                this.formsAllowed     = _formsAllowed;
                                                this.consoleLog(  `forms allowed = ${_formsAllowed}` );
                                       
                                          } catch (error ){
                                                this.consoleLog( error );
                                                this.formsAllowed = [];
                                          }
                                       
                                       },
                                       isFormAllowed( formName )  { 
                                           
                                          try {
                                                if ( this.formsAllowed.includes("all") ) {
                                                   return true;
                                                }  else { 
                                                   const findMe = formName.toLowerCase();
                                                   return (this.formsAllowed.includes( findMe ) );
                                                }
                                             } catch ( error ){
                                                this.consoleLog( error );
                                                return false;
                                          }
                        
                                       },
                                       setManageUsersPermission( permission )  { 
                                             this.showManageUsersButton  = permission;
                                       },
                                       getPhotosCount()    { 
                                             this.consoleLog("Getting Photo Count");
                                             return this.photos.length;
                                       },
                                       resetPhotos()    { 
                                             this.consoleLog("Resetting Photos");
                                             this.photos = [];
                                       },
                                       getPhotos()    { 
                                             this.consoleLog("Getting Photos");
                                             return this.photos;
                                       },
                                       setPhotos( photoArray )  { 
                                             this.consoleLog("Setting Photos");
                                             this.photos = photoArray;
                                       },
                                       saveFormsUploadStatus ( statusObject) {
                                          this.formsUploadStatus = statusObject;
                                       },
                                       saveFormObject( formName, formObject ) { 
                                          this.formReviewObjects[`${formName}`] = formObject;
                                       },
                                       retrieveFormObject( formName ) { 
                                          return ( this.formReviewObjects[ `${formName}` ] );
                                       },
                                       clearFormObject( formName ) { 
                                          this.formReviewObjects[ `${formName}` ] = null;
                                       },
                                       saveFormReviewResponse( object )  { 
                                          this.formReviewResponse = object;
                                       },
                                       resetFormReviewResponse()  { 
                                          this.formReviewResponse = null;
                                       },
                                       retrieveFormReviewResponse()  { 
                                          return ( this.formReviewResponse);
                                       },
                                       saveSignature( object )  { 
                                          this.signature = object;
                                       },
                                       resetSignature()  { 
                                          this.signature = null;
                                       },
                                       retrieveSignature()  { 
                                          return ( this.signature );
                                       }
                                 }
   });
                               
   new Vue( { 
      router,
      vuetify,
      render: h => h(App)
   }).$mount('#app')