<!--
    this component added on 4-17-24 
    to display the rebrand form photos upload progress
    it uses 'uploadingPhotosDialog' an object passed in as property.
    from reBrand.vue (te parent) 
  -->

<template>

   <div class="pa-3">

    <v-dialog  v-model="uploadingRebrandDialog.showDialog" persistent width="400">

            <v-card class="mx-auto" min-width="400">
              <v-card-title class="primary white--text">Uploading Rebrand Form</v-card-title>
 
                 <v-card-text>
    
                    <v-col cols="12" class="text-subtitle-1">
                      <span  v-if = "uploadingRebrandDialog.pdfMessage" >
                         <v-icon>mdi-file-pdf</v-icon>
                         {{ uploadingRebrandDialog.pdfMessage }}
                      </span>  
                    </v-col>

                    <v-divider></v-divider>
                     
                    <v-col cols="12" class="text-subtitle-1">
                        <span v-if = "uploadingRebrandDialog.photoUpload['status']">
                        <v-icon>mdi-file-pdf</v-icon>
                          {{ uploadingRebrandDialog.photoUpload['status']}} 
                       </span>
                    </v-col>
             
                    <v-card-text v-if = "uploadingRebrandDialog.photoUpload['current-boiler-plate']">
                      current-boiler-plate : {{ uploadingRebrandDialog.photoUpload['current-boiler-plate'] }}
                    </v-card-text> 
                    
                    <v-card-text v-if = "uploadingRebrandDialog.photoUpload['appliance-position']">
                      appliance-position : {{ uploadingRebrandDialog.photoUpload['appliance-position'] }}  
                    </v-card-text>
                    
                    <v-card-text v-if = "uploadingRebrandDialog.photoUpload['ecv-valve']">
                      ecv-valve : {{  uploadingRebrandDialog.photoUpload['ecv-valve'] }}
                    </v-card-text>         
                    
                    <v-card-text v-if = "uploadingRebrandDialog.photoUpload['fuel-type']">
                      fuel-type : {{  uploadingRebrandDialog.photoUpload['fuel-type'] }}
                    </v-card-text>         
                    
                    <v-card-text v-if = "uploadingRebrandDialog.photoUpload['power-supply']">
                    {{  uploadingRebrandDialog.photoUpload['power-supply'] }}
                    </v-card-text>      
                    
                    <v-card-text v-if = "uploadingRebrandDialog.photoUpload['building-work']">
                    {{  uploadingRebrandDialog.photoUpload['building-work'] }}
                    </v-card-text>     
                    
                    <v-card-text v-if = "uploadingRebrandDialog.photoUpload['additional-photo-1']">
                      {{ uploadingRebrandDialog.photoUpload['additional-photo-1'] }}
                    </v-card-text> 
                    
                    <v-card-text v-if = "uploadingRebrandDialog.photoUpload['additional-photo-2']">
                      {{ uploadingRebrandDialog.photoUpload['additional-photo-2'] }}
                    </v-card-text> 
                    
                    <v-card-text v-if = "uploadingRebrandDialog.photoUpload['additional-photo-3']">
                      {{ uploadingRebrandDialog.photoUpload['additional-photo-3'] }}
                    </v-card-text> 
                    
                    <v-card-text v-if = "uploadingRebrandDialog.photoUpload['additional-photo-4']">
                      {{ uploadingRebrandDialog.photoUpload['additional-photo-4'] }}
                    </v-card-text> 
                    
                    <v-card-text v-if = "uploadingRebrandDialog.photoUpload['additional-photo-5']">
                      {{ uploadingRebrandDialog.photoUpload['additional-photo-5'] }}
                    </v-card-text> 

                 </v-card-text>


            </v-card>
    </v-dialog>

   </div>
</template>
  
<script>
   
   export default {
     props : { "uploadingRebrandDialog" : Object },
   } 

</script>